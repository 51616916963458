    document.addEventListener('DOMContentLoaded', () => {
      const menuButton = document.getElementById('mainMenuButton');
      const navLinks = document.getElementById('navLinks');
      const mobileNav = document.getElementById('mobileNav');
      const closeButton = document.getElementById('closeButton');

      if (menuButton && navLinks && mobileNav && closeButton) {
        menuButton.addEventListener('click', () => {
          mobileNav.classList.remove('hidden');
        });

        closeButton.addEventListener('click', () => {
          mobileNav.classList.add('hidden');
        });
      }
    });
